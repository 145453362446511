import type { App } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

export const layoutRoutes: Array<RouteRecordRaw> = [
  {
    path: 'index',
    name: 'Index',
    meta: {
      title: '首页',
    },
    component: () => import('/@/views/index.vue'),
  },
  {
    path: 'verify',
    name: 'NfcVerify',
    meta: {
      title: 'NFC验证',
    },
    component: () => import('/@/views/nfc/NfcVerify.vue'),
  },
  {
    path: 'result',
    name: 'NfcResult',
    meta: {
      title: 'NFC验证结果',
    },
    component: () => import('/@/views/nfc/NfcResult.vue'),
  },
  {
    path: 'privacy-policy',
    name: 'PrivacyPolicy',
    meta: {
      title: '隐私政策',
    },
    component: () => import('/@/views/policy/PrivacyPolicy.vue'),
  },
  {
    path: 'user-agreement',
    name: 'UserAgreement',
    meta: {
      title: '用户授权协议',
    },
    component: () => import('/@/views/policy/UserAgreement.vue'),
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('/@/layout/index.vue'),
    // redirect: '/index',
    children: layoutRoutes,
  },
  // { path: '/:pathMatch(.*)*', redirect: '/' },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_PUBLIC_PATH),
  routes: routes,
});

export function setupRouter(app: App<Element>) {
  router.beforeEach((to, from, next) => {
    next();
  });
  app.use(router);
}
