import { createApp } from 'vue';
import App from './App.vue';
import { setupRouter } from './router';
import { Lazyload } from 'vant';
import 'vant/lib/index.css';
import 'vant/es/dialog/style';
import 'vant/es/toast/style';
import '/@/styles/index.less';
import { setupStore } from './store';

async function bootstrap() {
  const app = createApp(App);

  setupRouter(app);

  setupStore(app);

  app.use(Lazyload);
  
  app.mount('#app');
}

bootstrap();
